import Cookies from 'js-cookie';
// action type
export const SET_USER_ID = 'setUserId';
export const SET_MEMBER_ID = 'setClientId';
export const SET_COMPANY_ID = 'setCompany';
export const SET_SID = 'setSid';
export const SET_COMPANIES = 'setCompanies';
export const SET_PERIOD_ID = 'setPeriod';
export const SET_INSTANCE_ID = 'setInstanceID';
export const SET_VERSION = 'setVersion';
export const SET_SETTINGS = 'setSettings';
export const SET_PERIODS = 'setPeriods';
export const SET_TITLES = 'setTitles';
export const SET_TERMS = 'setTerms';
export const SET_ACCEPTED_TERMS = 'setAcceptedTerms';
export const SET_AGE = 'setAge';
export const SET_MEMBER_COMPANIES = 'setMemberCompanies';
export const DELETE_COMPANY = 'deleteCompany';
export const SET_KEYVAL = 'setKeyVal';
export const SET_CUSTOMER = 'setCustomer';
export const SET_PROFILE = 'setProfile';
export const SET_USER = 'setUser';
export const SET_USER_PREFS = 'setUserPrefs';
export const SET_SEARCH_RESULT_PREF = 'setSearchResultPref';
export const SET_SEARCH_USERS_RESULT_PREF = 'setSearchUsersResultPref';


// mutation types
export const UPDATE_USER_ID = 'updateUserId';
export const UPDATE_MEMBER_ID = 'updateClientId';
export const UPDATE_COMPANY_ID = 'updateCompany';
export const UPDATE_SID = 'updateSid';
export const UPDATE_COMPANIES = 'updateCompanies';
export const DELETE_COMPANY_MUTATE = 'deleteCompanyMutate';
export const UPDATE_PERIOD_ID = 'updatePeriod';
export const UPDATE_INSTANCE_ID = 'updateInstanceID';
export const UPDATE_VERSION = 'updateVersion';
export const UPDATE_SETTINGS = 'updateSettings';
export const UPDATE_PERIODS = 'updatePeriods';
export const UPDATE_TITLES = 'updateTitles';
export const UPDATE_TERMS = 'updateTerms';
export const UPDATE_ACCEPTED_TERMS = 'updateAcceptedTerms';
export const UPDATE_AGE = 'updateAge';
export const UPDATE_CUSTOMER = 'updateCustomer';
export const UPDATE_KEYVAL = 'updateKeyVal';
export const UPDATE_MEMBER_COMPANIES = 'updateMemberCompanies';
export const UPDATE_PROFILE = 'updateProfile';
export const UPDATE_USER = 'updateUser';
export const UPDATE_USER_PREFS = 'updateUserPrefs';
export const UPDATE_SEARCH_RESULT_PREF = 'updateSearchResultPref';
export const UPDATE_SEARCH_USERS_RESULT_PREF = 'updateSearchUsersResultPref';

const cid = Cookies.get('company_id'); // load the current company id from cookie
let pid = Cookies.get('period_id');
if (pid !== undefined && pid !== null) {
  pid = parseInt(pid);
}
let uid = Cookies.get('user_id');
if (uid !== undefined && uid !== null) {
  uid = parseInt(uid);
}

const clid = Cookies.get('client_id');
const sid = Cookies.get('sid');
const intanceId = Cookies.get('intanceId');
const version = Cookies.get('version');
const settings = Cookies.get('settings');
const keyvals = Cookies.get('keyvals');
const profile = Cookies.get('profile');
const search_result_pref = Cookies.get('search_result_pref');
const user_prefs = Cookies.get('user_prefs');
const search_users_result_pref = Cookies.get('search_users_result_pref');
const customer = Cookies.get('customer');

const state = {
  currentMemberId: clid ? clid : '',
  currentUserId: uid ? uid : '',
  currentCompanyId: cid ? cid : '', // if its assigned in a cookie, assign it to the state
  currentPeriodId: pid ? pid : -1,
  settings: settings !== undefined && settings !== null && settings !== 'undefined' ? JSON.parse(settings) : {},
  periods: [],
  titles: [],
  companies: [],
  memberCompanies: [],
  profile: profile !== undefined && profile !== null && profile !== 'undefined' ? JSON.parse(profile) : {},
  terms: [],
  acceptedTerms: [],
  keyvals: keyvals && keyvals !== 'undefined' && keyvals !== null ? JSON.parse(keyvals) : {},
  sid: sid ? sid : '',
  intanceId: intanceId ? intanceId : '',
  version: version ? version : '0.0.0',
  searchResultPref: search_result_pref !== undefined && search_result_pref !== null && search_result_pref !== 'undefined' ? JSON.parse(search_result_pref) : [],
  searchUsersResultPref: search_users_result_pref !== undefined && search_users_result_pref !== null && search_users_result_pref !== 'undefined' ? JSON.parse(search_users_result_pref) : [],
  user: {},
  user_prefs: user_prefs !== undefined && user_prefs !== null && user_prefs !== 'undefined' ? JSON.parse(user_prefs) : {},
  customer: customer !== undefined && customer !== null && customer !== 'undefined' ? JSON.parse(customer) : {},
};

const getters = {
  currentMemberId: state => state.currentMemberId,
  currentUserId: state => state.currentUserId,
  currentCompanyId: state => state.currentCompanyId, // register a getter so its easy to get it from the app
  sid: state => state.sid,
  currentPeriodId: state => state.currentPeriodId,
  periods: state => state.periods,
  titles: state => state.titles,
  terms: state => state.terms,
  acceptedTerms: state => state.acceptedTerms,
  companies: state => state.companies,
  memberCompanies: state => state.memberCompanies,
  age: state => state.age,
  keyvals: state => state.keyvals,
  instanceId: state => state.intanceId,
  version: state => state.version,
  settings: state => state.settings,
  profile: state => state.profile,
  searchResultPref: state => state.searchResultPref,
  searchUsersResultPref: state => state.searchUsersResultPref,
  user: state => state.user,
  user_prefs: state => state.user_prefs,
  customer: state => state.customer,
};

const actions = {

  [SET_CUSTOMER](state, payload) {
    state.commit(UPDATE_CUSTOMER, payload);
  },

  [SET_USER_PREFS](state, payload) {
    state.commit(UPDATE_USER_PREFS, payload);
  },
  [SET_SEARCH_RESULT_PREF](state, payload) {
    state.commit(UPDATE_SEARCH_RESULT_PREF, payload);
  },
  [SET_SEARCH_USERS_RESULT_PREF](state, payload) {
    state.commit(UPDATE_SEARCH_USERS_RESULT_PREF, payload);
  },
  [SET_PROFILE](state, payload) {
    state.commit(UPDATE_PROFILE, payload);
  },
  [SET_USER](state, payload) {
    state.commit(UPDATE_USER, payload);
  },
  [SET_KEYVAL](state, payload) {
    state.commit(UPDATE_KEYVAL, payload);
  },
  [SET_SETTINGS](state, payload) {
    state.commit(UPDATE_SETTINGS, payload);
  },
  [SET_AGE](state, payload) {
    state.commit(UPDATE_AGE, payload);
  },
  [SET_INSTANCE_ID](state, payload) {
    state.commit(UPDATE_INSTANCE_ID, payload);
  },
  [SET_VERSION](state, payload) {
    state.commit(UPDATE_VERSION, payload);
  },
  [SET_MEMBER_ID](state, payload) {
    state.commit(UPDATE_MEMBER_ID, payload);
  },
  [SET_USER_ID](state, payload) {
    state.commit(UPDATE_USER_ID, payload);
  },
  // action to set the state currentCompanyId from anywhere in the app
  [SET_COMPANY_ID](state, payload) { 
    state.commit(UPDATE_COMPANY_ID, payload);
  },
  [SET_SID](state, payload) {
    state.commit(UPDATE_SID, payload);
  },
  [SET_COMPANIES](state, payload) {
    state.commit(UPDATE_COMPANIES, payload);
  },
  [SET_MEMBER_COMPANIES](state, payload) {
    state.commit(UPDATE_MEMBER_COMPANIES, payload);
  },
  [SET_PERIOD_ID](state, payload) {
    state.commit(UPDATE_PERIOD_ID, payload);
  },
  [SET_PERIODS](state, payload) {
    state.commit(UPDATE_PERIODS, payload);
  },
  [SET_TITLES](state, payload) {
    state.commit(UPDATE_TITLES, payload);
  },
  [SET_TERMS](state, payload) {
    state.commit(UPDATE_TERMS, payload);
  },
  [SET_ACCEPTED_TERMS](state, payload) {
    state.commit(UPDATE_ACCEPTED_TERMS, payload);
  },
  [DELETE_COMPANY](state, payload) {
    state.commit(DELETE_COMPANY_MUTATE, payload);
  }
};

const mutations = {

  [UPDATE_CUSTOMER](state, payload) {
    state.customer = payload;
    Cookies.set('customer', JSON.stringify(state.customer));
  },
  

  [UPDATE_USER_PREFS](state, payload) {
    state.user_prefs = payload;
    
    if (state.user_prefs) {
      Cookies.set('user_prefs', JSON.stringify(state.user_prefs));
    }
    
  },
  [UPDATE_SEARCH_RESULT_PREF](state, payload) {
    state.searchResultPref = payload;
    Cookies.set('search_result_pref', JSON.stringify(state.searchResultPref));
  },
  [UPDATE_SEARCH_USERS_RESULT_PREF](state, payload) {
    state.searchUsersResultPref = payload;
    Cookies.set('search_users_result_pref', JSON.stringify(state.searchUsersResultPref));
  },
  [UPDATE_PROFILE](state, payload) {
    state.profile = payload;
    Cookies.set('profile', JSON.stringify(state.profile));
  },
  [UPDATE_USER](state, payload) {
    state.user = payload;
  },
  [UPDATE_KEYVAL](state, payload) {
    state.keyvals = payload;
    Cookies.set('keyvals', JSON.stringify(state.keyvals));
  },
  [UPDATE_AGE](state, payload) {
    state.age = payload;
  },
  [UPDATE_INSTANCE_ID](state, payload) {
    state.intanceId = payload;
    Cookies.set('intanceId', payload);
  },
  [UPDATE_SETTINGS](state, payload) {
    state.settings = payload;
    Cookies.set('settings', JSON.stringify(payload));
  },
  [UPDATE_VERSION](state, payload) {
    state.version = payload;
    Cookies.set('version', payload);
  },
  [UPDATE_MEMBER_ID](state, payload) {
    state.currentMemberId = payload;
    Cookies.set('client_id', payload);
  },
  [UPDATE_USER_ID](state, payload) {
    state.currentUserId = payload;
    Cookies.set('user_id', payload);
  },
  // the actual mutation of the object and setting the cookie. When value is set here all listeners in the app is notified.
  [UPDATE_COMPANY_ID](state, payload) { 
    state.currentCompanyId = payload;
    if (payload) {
      Cookies.set('company_id', payload);
    }
  },
  [UPDATE_SID](state, payload) {
    state.sid = payload;
    Cookies.set('sid', payload);
  },
  [DELETE_COMPANY_MUTATE](state, payload) {
    var companies = state.companies.filter(item => item.id !== payload);

    state.companies = companies;

    localStorage.setItem('companies', JSON.stringify(state.companies));
  },
  [UPDATE_MEMBER_COMPANIES](state, payload) {
    state.memberCompanies = payload;
  },
  [UPDATE_COMPANIES](state, payload) {

    var light = [];

    let sid_company;

    for (var i = 0; i < payload.length; ++i) {
      if (payload[i].company_id === payload[i].sid) {
        sid_company = {
          company_id: payload[i].company_id,
          id:         payload[i].company_id,
          name:       payload[i].name,
          periods:    payload[i].periods,
          titles:     payload[i].titles,
          settings:   payload[i].settings,
          diag:       payload[i].diag,
          shortname:  payload[i].shortname,
          cnum:       payload[i].cnum,
          org_number: payload[i].org_number,
        };
        continue;
      }
      light.push({
        company_id:   payload[i].company_id,
        id:           payload[i].company_id,
        name:         payload[i].name,
        periods:      payload[i].periods,
        titles:       payload[i].titles,
        settings:     payload[i].settings,
        diag:         payload[i].diag,
        shortname:    payload[i].shortname,
        cnum:         payload[i].cnum,
        org_number:   payload[i].org_number,
      });

    }

    state.companies = light;

  },
  [UPDATE_PERIOD_ID](state, payload) {
    state.currentPeriodId = payload;
    Cookies.set('period_id', payload);
  },
  [UPDATE_PERIODS](state, payload) {
    try {
      state.periods = payload.sort(function(a, b) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });
    } catch {
      state.periods = payload;
    }
  },
  [UPDATE_TITLES](state, payload) {
    state.titles = payload;
  },
  [UPDATE_TERMS](state, payload) {
    state.terms = payload;
  },
  [UPDATE_ACCEPTED_TERMS](state, payload) {
    state.acceptedTerms = payload;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
