<!-- A custom Select input wrapper -->
<!-- We use this so that we can easily switch in case it deprecates in Vue3 -->

<!--
    <memlist-select-input
      v-model="test"
      class="mb-8"
      :placeholder="$t('COMMON.CREATED_FROM')"
    >
    </memlist-select-input>
-->
<template>

  <div class="memlist-text-input-container mt-2">

    <div v-if="layout === 'horizontal'">

      <div class="horizontal1">
        <label>{{ title }}</label>
      </div>
      
      <div class="horizontal2">
        <v-select
          v-model="local_value"
          variant="outlined"
          item-title="text"
          item-value="value"
          :items="items"
          outlined
          dense
          @input="on_input"
          :placeholder="placeholder"
          :disabled="disabled"
          :class="state_class"
        ></v-select>

        <div class="error-text" v-if="required && invalid_text && invalid_text.length > 0 && local_state === false">{{ invalid_text }}</div>

        <div class="info-text">{{ info_text }}</div>
      </div>
    </div>

    <div v-if="layout === 'vertical'">

      <div class="vertical1">
        <label>{{ title }}</label>
      </div>

      <div class="vertical2">
        <v-select
          v-model="local_value"
          variant="outlined"
          item-title="text"
          item-value="value"
          :items="items"
          outlined
          dense
          @input="on_input"
          :placeholder="placeholder"
          :disabled="disabled"
          :class="state_class"
        ></v-select>

        <div class="error-text" v-if="required && invalid_text && invalid_text.length > 0 && local_state === false">{{ invalid_text }}</div>

        <div class="info-text">{{ info_text }}</div>
      </div>
    </div>
  </div>
</template>
<script>

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import dayjs from 'dayjs';
import { validate_email } from '@/core/services/email';
import { validatePersonnr } from '@/core/services/personnr';

export default {

  name: 'MemlistSelectInput',

  props: {
    layout: {
      type: String,
      default: 'horizontal'
    },
    title: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    validate: {
      type: Number,
      default: 0
    },
    invalid_text: {
      type: String,
      default: ''
    },
    info_text: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    validation_type: {
      type: String,
      default: 'TEXT'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: []
    },
    value: [String, Number], // For Vue 2
    modelValue: [String, Number], // For Vue 3
    placeholder: {
      type: String,
      default: ''
    },
    validation_function: {
      type: Function,
      default: null
    }
  },

  mixins: [ toasts ],

  emits: ['input', 'change'],

  components: {
    
  },

  watch: {
    validate(newValue) {
      if (newValue !== 0) {
        this.local_state = this.validate_input();
      }
    },
    
    modelValue(newValue) {
      this.local_value = newValue;
    },

    value(newValue) {
      this.local_value = newValue;
    }
  },

  computed: {
    state_class() {

      if (!this.required) {
        return 'memlist-text-input';
      }

      if (this.local_state !== null) {
        return this.local_state ? 'memlist-text-input success1' : 'memlist-text-input failure1';
      }

      return 'memlist-text-input';
    }
  },

  mounted() {
    this.local_value = this.modelValue || this.value;
  },

  methods: {

    validate_input() {

      if (this.validation_function !== null) {
        const res = this.validation_function(this.local_value);
        
        this.$emit('validated', this.name, this.local_value, res);

        this.local_state = res;

        return res;
      }

      if (!this.required) {
        return true;
      }

      if (this.local_value === undefined) {
        this.local_value = '';
      }

      if (this.disabled) {
        this.$emit('validated', this.name, this.local_value, res);
        return true;
      }
      
      if (this.required && !this.local_value) {
        this.$emit('validated', this.name, this.local_value, false);
        return false;
      }

      if (this.validation_type === 'TEXT') {
        
        const res = this.local_value.length > 0;

        this.$emit('validated', this.name, this.local_value, res);

        return res;
      }
      else if (this.validation_type === 'PERSONNR') {
        const validated = validatePersonnr(this.local_value);

        if (validated.valid) {
          this.local_value = validated.formatted;

          this.$emit('validated', this.name, this.local_value, true);
          return true;
        }
        else {
          this.$emit('validated', this.name, this.local_value, false);
          return false;
        }
      }
      else if (this.validation_type === 'DOB') {
        const validated = validatePersonnr(this.local_value);

        if (validated.success) {
          this.local_value = validated.birthdate;

          this.$emit('validated', this.name, this.local_value, true);
          return true;
        }
        else {
          this.$emit('validated', this.name, this.local_value, false);
          return false;
        }
      }
      else if (this.validation_type === 'EMAIL') {
        const res = validate_email(this.local_value);

        this.$emit('validated', this.name, this.local_value, res);

        return res;
      }

      this.$emit('validated', this.name, this.local_value, true);
    },

    on_input(event) {

      this.local_state = this.validate_input();   
      
      this.$emit('update:value', this.name, this.local_value); // For Vue 3
    }
  },


  data() {
    return {
      local_state: null,
      local_value: null
    };
  }
};
</script>

<style lang="css" scoped>

.horizontal1 {
  float: left;
  width: 50%;
  text-align: right;
  padding-right: 16px;
  margin-top: 8px;
}

.horizontal2 {
  float: left;
  width: 50%;  
}

label {
  font-weight: bold;
}

.vertical1 {
  margin-top: 8px;
}

.vertical2 {
  margin-right: 8px;
}

:deep .success1 {
  border: 1px solid #1BC5BD !important;
}

:deep .failure1 {
  border: 1px solid #fd397a !important;
}

:deep .mx-input {
  height: calc(1.5em + 1.3rem + 1px);
  cursor: pointer;
}

:deep input:disabled {
  background-color: #E4E6EF;
  opacity: 1;
}

.error-text {
  color: #fd397a;
  font-style: italic;
  margin-top: 6px;
}

:deep .memlist-text-input {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #3F4254;
  background-color: #ffffff !important;
  background-clip: padding-box;
  border: 1px solid #E4E6EF;
  border-radius: 0.42rem;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.info-text {
  margin-top: 12px !important;
  font-style: italic;
  color: #888888;
}

</style>
